<template>
  <div class="login">
    <div class="nav">
      <h1>账号密码注册</h1>
      <router-link to="/login" class="register">登录</router-link>
    </div>
    <div class="main">
      <van-form :model="loginForm">
        <van-field v-model="loginForm.reguser" left-icon="manager" :rules="[{ required: true, message: '请填写用户名' }]"
          placeholder="请输入用户名" />
        <van-field v-model="loginForm.regpswd" left-icon="lock" :right-icon="show" @click-right-icon="cut"
          :type="typepass" :rules="[{ required: true, message: '请填写密码' }]" placeholder="请输入密码" />
        <van-field v-model="loginForm.cfmpswd" left-icon="lock" :right-icon="show" @click-right-icon="cut"
          :type="typepass" :rules="[{ required: true, message: '请填写密码' }]" placeholder="请再次输入密码" />
        <!-- <router-link to="" class="pass">忘记密码？</router-link> -->
        <div style="margin: 30px;">
          <van-button round block type="info" @click="submit">注册</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loginForm: {
        reguser: '', // 账号
        regpswd: '', // 密码
        cfmpswd: ''
      },
      show: 'eye', // 密码显示隐藏
      typepass: 'password'
    }
  },
  methods: {
    // 显示隐藏密码
    cut (e) {
      if (e.target._prevClass === 'van-icon van-icon-eye') {
        this.show = 'browsing-history'
        this.typepass = 'text'
      } else {
        this.show = 'eye'
        this.typepass = 'password'
      }
    },
    // 登录请求
    submit () {
      console.log(123123);
      if (this.loginForm.username === 'admin' && this.loginForm.password === '123456') {
        this.$router.push('/home')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10% 0;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }

    .register {
      font-size: 18px;
      color: black;
    }
  }

  .main {
    padding: 20% 0;

    .van-cell::after {
      border-bottom: 2px solid black
    }

    .van-cell {
      margin-top: 25px;
    }

    .pass {
      display: block;
      padding: 15px 16px;
      color: black;
    }
  }
}
</style>
