<template>
  <div class="home">
    <div class="homes"></div>
    <div class="nav">
      <div class="box">
        <van-icon name="user-circle-o" size="50px" color="white" /><span>{{
          loginName
        }}</span>
      </div>
      <div class="link">
        <van-icon name="wap-nav" size="20px" @click="lists" />
        <div class="option">
          <van-popup
            v-model="isShow"
            position="top"
            :style="{ height: '280px' }"
          >
            <ul>
              <router-link to="/home">
                <li>
                  <img
                    src="../../assets/images/img/fontImgs/zy.png"
                    alt=""
                  />返回主页
                </li>
              </router-link>

              <router-link to="/policelist">
                <li>
                  <img
                    src="@/assets/images/img/fontImgs/bj.png"
                    alt=""
                  />报警列表
                </li>
              </router-link>

              <router-link to="/errorlist">
                <li>
                  <img
                    src="@/assets/images/img/fontImgs/gz.png"
                    alt=""
                  />故障列表
                </li>
              </router-link>

              <router-link to="/echarts">
                <li>
                  <img
                    src="@/assets/images/img/fontImgs/sz.png"
                    alt=""
                  />运行报表
                </li>
              </router-link>

              <router-link to="/pwdm">
                <li>
                  <img
                    src="@/assets/images/img/fontImgs/mm.png"
                    alt=""
                  />修改密码
                </li>
              </router-link>

              <a href="" @click="quit">
                <li>
                  <img
                    src="@/assets/images/img/fontImgs/fh.png"
                    alt=""
                  />安全退出
                </li>
              </a>
            </ul>
          </van-popup>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="top">
        <van-dropdown-menu class="select">
          <van-dropdown-item
            v-model="value"
            :options="option"
            @click.native="qiehuan(value)"
          />
        </van-dropdown-menu>
      </div>
      <van-swipe
        :loop="false"
        class="tranx"
        ref="tranx"
        style="padding-bottom: 20px"
      >
        <van-swipe-item v-for="item in list" :key="item.uuid" ref="swipeItem">
          <div class="somes">
            <div class="contain">
              <div class="piece">
                <div class="header">
                  <h3 @click="look(item.title)">{{ item.title }}</h3>
                  <p v-if="subtit">
                    额定值（功率: {{ item.subTitle[0].Value }}kw, 扬程:
                    {{ item.subTitle[1].Value }}M, 流量:
                    {{ item.subTitle[2].Value }}m³/h
                  </p>
                </div>
                <div class="content">
                  <table>
                    <tr>
                      <td>
                        <span class="iconfont icon-gongpinpinshuai"></span>
                        工频/变频/停止
                      </td>
                      <td>{{ item.info.powerFrequency }}</td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-dianliu"></span> 变频电流
                      </td>
                      <td>
                        <span>{{ item.info.frequencyCurrent }}</span
                        >A
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-pinshuai"></span> 变频频率
                      </td>
                      <td>
                        <span>{{ item.info.frequencyHz }}</span
                        >Hz
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-baojing01"></span> 变频故障
                      </td>
                      <td>
                        <div :class="item.info.frequencyFault"></div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-dianliu3"></span> 工频电流
                      </td>
                      <td>
                        <span>{{ item.info.powerCurrent }}</span
                        >A
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-alarm-full"></span> 工频故障
                      </td>
                      <td>
                        <div :class="item.info.powerFault"></div>
                      </td>
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td>
                        <span class="iconfont icon-wenduji1"></span> 电机温度
                      </td>
                      <td>
                        <span>{{ item.info.motorTemperature }}</span
                        >℃
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-wenduji1"></span>
                        前端轴承温度
                      </td>
                      <td>
                        <span>{{ item.info.fontBearingTemperature }}</span
                        >℃
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-wenduji1"></span>
                        后端轴承温度
                      </td>
                      <td>
                        <span>{{ item.info.backBearingTemperature }}</span
                        >℃
                      </td>
                    </tr>
                    <tr>
                      <td><span class="iconfont icon-zhendong"></span> 振动</td>
                      <td>
                        <span>{{ item.info.vibration }}</span
                        >Hz
                      </td>
                    </tr>
                  </table>

                  <table>
                    <tr>
                      <td>
                        <span class="iconfont icon-shuffle"></span>
                        就地/远程状态
                      </td>
                      <td>{{ item.info.operationStatus }}</td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-yuanchengfei"></span>
                        手自动切换
                      </td>
                      <td class="Remote">
                        手动<van-switch
                          :value="item.info.manualAndAutoSwitch"
                          :disabled="item.disabled"
                          @input="onInput(item)"
                          size="24px"
                        />自动
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="iconfont icon-yuancheng01-copy"></span>
                        远程启停
                      </td>
                      <td class="Remote">
                        停止<van-switch
                          :value="item.info.remoteStartAndStop"
                          :disabled="item.disableds"
                          @input="getYuanApi(item)"
                          size="24px"
                        />启动
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <img
                          src="../../assets/images/set.png"
                          style="width: 17px; height: 17px; margin-bottom: -3px"
                          alt=""
                        />
                        频率设定
                      </td>
                      <td class="Remote">
                        <input
                          type="text"
                          style="width: 60px"
                          v-if="isFrequencySetting"
                          @focus="changeIsFrequencySetting(1)"
                          v-model="item.info.FrequencySetting"
                        />
                        <input
                          type="text"
                          style="width: 60px"
                          @blur="changeIsFrequencySetting(0)"
                          v-else
                          v-model="FrequencySettingList"
                        />
                        <button @click="getPinLv(item)" style="font-size: 14px">
                          设置
                        </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import { waterList, automaticApi, remoteApi, pinLvApi } from "@/api/api.js"; //
import { Toast } from "vant";

// const ws = new WebSocket("ws://pumpwebsocketlan.gbhjt.com:4104");
export default {
  data() {
    return {
      list: [], // 水泵列表
      loginName: "", // 登录名
      value: 0, // 选择的哪个水泵
      num: 0,
      state: false,
      isShow: false,
      option: [],
      subtit: [{ Value: 0 }, { Value: 0 }, { Value: 0 }],
      FrequencySettingList: null,
      isFrequencySetting: true,
      wss: null,
    };
  },
  created() {
    this.loginName =
      "欢迎您" + " " + localStorage.getItem("loginName") + " " + "!";
    this.getWaterList();
    this.initWebSocket();
  },
  // mounted() {
  //   ws.addEventListener("open", this.handleWsOpen.bind(this), false);
  //   ws.addEventListener("close", this.handleWsClose.bind(this), false);
  //   ws.addEventListener("error", this.handleWsError.bind(this), false);
  //   ws.addEventListener("message", this.handleWsMessage.bind(this), false);
  // },
  beforeDestroy() {
    this.wss.close();
  },
  methods: {
    initWebSocket() {
      this.wss = new WebSocket("wss://pumpwebsocketlan.gbhjt.com:4204");
      this.wss.onmessage = this.handleWsMessage;
      this.wss.onopen = this.handleWsOpen;
      this.wss.onerror = this.handleWsError;
      this.wss.onclose = this.handleWsClose;
    },

    // 切换频率输入框
    changeIsFrequencySetting(i) {
      if (i == 1) {
        this.isFrequencySetting = false;
      } else if (!this.FrequencySettingList & (i == 0)) {
        this.isFrequencySetting = true;
      }
    },
    // 切换水泵跳转指定页面
    qiehuan(index) {
      const slide = document.querySelector(".van-swipe__track");
      slide.style.transform = `translateX(-${this.option[index].translateX}vw)`;
    },
    // 连接成功
    handleWsOpen(e) {
      console.log("open2");
      // 连接完成向ws服务发送登录信息
      this.wss.send(
        JSON.stringify({
          loginName: localStorage.getItem("loginName"),
          puuid: localStorage.getItem("staticPuid"),
          type: "pumps",
        })
      );
    },
    // 断开连接信息
    handleWsClose(e) {
      console.log("close2");
      // ws = new WebSocket("ws://pumpwebsocketlan.gbhjt.com:4103");
      // ws.addEventListener("open", this.handleWsOpen.bind(this), false);
    },
    // ws错误信息
    handleWsError(e) {
      console.log("error2", e);
      // Toast({
      //   message: "水泵socket连接失败",
      //   position: "top",
      // });
      this.initWebSocket();
      // ws.addEventListener("open", this.handleWsOpen.bind(this), false);
    },
    // ws推送的数据做出处理
    handleWsMessage(e) {
      const wsList = JSON.parse(e.data);
      // console.log("数据", wsList);
      if (wsList.data.length > 0) {
        for (let i = 0; i < wsList.data.length; i++) {
          for (let j = 0; j < this.list.length; j++) {
            if (wsList.data[i].uuid === this.list[j].uuid) {
              for (const k in wsList.data[i].info) {
                if (wsList.data[i].info[k]) {
                  this.list[j].info[k] = wsList.data[i].info[k];
                }
              }
            }
          }
        }
      }
      this.list.forEach((item) => {
        // 工频/变频/停止
        if (
          item.info.powerFrequency === 0 ||
          item.info.powerFrequency === "0"
        ) {
          item.info.powerFrequency = "停止";
        } else if (
          item.info.powerFrequency === 1 ||
          item.info.powerFrequency === "1"
        ) {
          item.info.powerFrequency = "故障";
        } else if (
          item.info.powerFrequency === 2 ||
          item.info.powerFrequency === "2"
        ) {
          item.info.powerFrequency = "变频运行";
        } else if (
          item.info.powerFrequency === 3 ||
          item.info.powerFrequency === "3"
        ) {
          item.info.powerFrequency = "工频运行";
        } else {
        }
        // 手自动切换
        if (
          item.info.manualAndAutoSwitch === 0 ||
          item.info.manualAndAutoSwitch === "0"
        ) {
          item.info.manualAndAutoSwitch = false;
        } else if (
          item.info.manualAndAutoSwitch === 1 ||
          item.info.manualAndAutoSwitch === "1"
        ) {
          item.info.manualAndAutoSwitch = true;
        } else {
        }
        // 远程启停
        if (
          item.info.remoteStartAndStop === 0 ||
          item.info.remoteStartAndStop === "0"
        ) {
          item.info.remoteStartAndStop = false;
        } else if (
          item.info.remoteStartAndStop === 1 ||
          item.info.remoteStartAndStop === "1"
        ) {
          item.info.remoteStartAndStop = true;
        } else {
        }
        // 就地/远程
        if (
          item.info.operationStatus === 0 ||
          item.info.operationStatus === "0"
        ) {
          item.info.operationStatus = "就地";
          item.disabled = true;
          item.disableds = true;
        } else if (
          item.info.operationStatus === 1 ||
          item.info.operationStatus === "1"
        ) {
          item.info.operationStatus = "远程";
          item.disabled = false;
          item.disableds = false;
        } else {
        }
        // 远程+自动无法启停
        if (
          item.info.operationStatus === "远程" &&
          item.info.manualAndAutoSwitch === true
        ) {
          item.disableds = true;
        }
        // 变频故障
        if (
          item.info.frequencyFault === 0 ||
          item.info.frequencyFault === "0"
        ) {
          item.info.frequencyFault = "grey";
        } else if (
          item.info.frequencyFault === 1 ||
          item.info.frequencyFault === "1"
        ) {
          item.info.frequencyFault = "green";
        } else if (
          item.info.frequencyFault === 2 ||
          item.info.frequencyFault === "2"
        ) {
          item.info.frequencyFault = "yellow";
          item.disabled = true;
          item.disableds = true;
        } else {
        }
        // 工频故障
        if (item.info.powerFault === 0 || item.info.powerFault === "0") {
          item.info.powerFault = "grey";
        } else if (item.info.powerFault === 1 || item.info.powerFault === "1") {
          item.info.powerFault = "green";
        } else if (item.info.powerFault === 2 || item.info.powerFault === "2") {
          item.info.powerFault = "yellow";
          item.disabled = true;
          item.disableds = true;
        } else {
        }
      });
    },
    lists() {
      this.num++;
      if (this.num === 1) {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.num = 0;
      }
    },

    // 获取水泵数据
    getWaterList() {
      waterList({
        data: {
          puuid: this.$route.query.id,
          action: "getPumpsListInfo",
          uuid: "all",
          run: true,
          stop: true,
          fault: true,
          sAccount: localStorage.getItem("loginName"),
        },
      })
        .then((res) => {
          this.list = [...res.data.data];
          // this.FrequencySettingList
          for (let i = 0; i < this.list.length; i++) {
            const newArr = {
              text: this.list[i].title,
              value: i,
              uuid: this.list[i].uuid,
              translateX: i * 100,
            };
            this.option.push(newArr);
          }
          this.list.forEach((item) => {
            // 标题下标判断
            if (item.subTitle.length === 0) {
              item.subTitle = this.subtit;
            }
            // 工频/变频/停止
            if (
              item.info.powerFrequency === 0 ||
              item.info.powerFrequency === "0"
            ) {
              item.info.powerFrequency = "离线";
            } else if (
              item.info.powerFrequency === 1 ||
              item.info.powerFrequency === "1"
            ) {
              item.info.powerFrequency = "工频运行";
            } else if (
              item.info.powerFrequency === 2 ||
              item.info.powerFrequency === "2"
            ) {
              item.info.powerFrequency = "变频运行";
            } else {
            }
            // 手自动切换
            if (
              item.info.manualAndAutoSwitch === 0 ||
              item.info.manualAndAutoSwitch === "0"
            ) {
              item.info.manualAndAutoSwitch = false;
            } else if (
              item.info.manualAndAutoSwitch === 1 ||
              item.info.manualAndAutoSwitch === "1"
            ) {
              item.info.manualAndAutoSwitch = true;
            } else {
            }
            // 远程启停
            if (
              item.info.remoteStartAndStop === 0 ||
              item.info.remoteStartAndStop === "0"
            ) {
              item.info.remoteStartAndStop = false;
            } else if (
              item.info.remoteStartAndStop === 1 ||
              item.info.remoteStartAndStop === "1"
            ) {
              item.info.remoteStartAndStop = true;
            } else {
            }
            // 就地/远程
            if (
              item.info.operationStatus === 0 ||
              item.info.operationStatus === "0"
            ) {
              item.info.operationStatus = "就地";
              item.disabled = true;
              item.disableds = true;
            } else if (
              item.info.operationStatus === 1 ||
              item.info.operationStatus === "1"
            ) {
              item.info.operationStatus = "远程";
            } else {
            }
            // 远程+自动无法启停
            if (
              item.info.operationStatus === "远程" &&
              item.info.manualAndAutoSwitch === true
            ) {
              item.disableds = true;
            }
            // 变频故障
            if (
              item.info.frequencyFault === 0 ||
              item.info.frequencyFault === "0"
            ) {
              item.info.frequencyFault = "grey";
            } else if (
              item.info.frequencyFault === 1 ||
              item.info.frequencyFault === "1"
            ) {
              item.info.frequencyFault = "green";
            } else if (
              item.info.frequencyFault === 2 ||
              item.info.frequencyFault === "2"
            ) {
              item.info.frequencyFault = "yellow";
            } else {
            }
            // 工频故障
            if (item.info.powerFault === 0 || item.info.powerFault === "0") {
              item.info.powerFault = "grey";
            } else if (
              item.info.powerFault === 1 ||
              item.info.powerFault === "1"
            ) {
              item.info.powerFault = "green";
            } else if (
              item.info.powerFault === 2 ||
              item.info.powerFault === "2"
            ) {
              item.info.powerFault = "yellow";
            } else {
            }
          });
        })
        .catch(console.error());
    },
    // 退出登录
    quit() {
    //   localStorage.removeItem("userinfo");
      this.$router.push("/login");
    },
    // 跳转echart
    look(tit) {
      localStorage.setItem("wname", tit);
      this.$router.push({
        path: "/echarts",
      });
    },
    // 手自动切换
    onInput(item) {
      const mun = item.info.manualAndAutoSwitch === false ? 1 : 0;
      automaticApi({
        data: {
          action: "ManualAutomaticSwitching",
          state: mun,
          uuid: item.uuid,
          sAccount: localStorage.getItem("loginName"),
        },
      }).then((res) => {
        if (res.data.Msg == "操作失败:时间间隔不足3秒") {
          Toast.fail(res.data.Msg);
        } else if (res.data.Msg == "指令发送失败") {
          Toast.fail(res.data.Msg);
          return;
        } else {
          Toast.success(res.data.Msg);
        }
      });
    },
    // 远程启停
    getYuanApi(item) {
      const ynum = item.info.remoteStartAndStop === false ? 1 : 0;

      remoteApi({
        data: {
          action: "RemoteStartAndStop",
          state: ynum,
          uuid: item.uuid,
          sAccount: localStorage.getItem("loginName"),
        },
      }).then((res) => {
        if (res.data.Msg == "操作失败:时间间隔不足3秒") {
          Toast.fail(res.data.Msg);
        } else if (res.data.Msg == "指令发送失败") {
          Toast.fail(res.data.Msg);
          return;
        } else {
          Toast.success(res.data.Msg);
        }
      });
    },

    // 频率设定
    getPinLv(item) {
      console.log(item.info.frequencyFault, item.info.powerFault);
      if (
        item.info.frequencyFault == "yellow" ||
        item.info.powerFault == "yellow"
      ) {
        Toast.fail("故障状态下，不可操作");
        return;
      }
      if (!this.FrequencySettingList) {
        this.FrequencySettingList = item.info.FrequencySetting;
      }
      pinLvApi({
        data: {
          action: "pumpFrequencySetting",
          sValue: this.FrequencySettingList,
          // sValue: 45.0,
          uuid: item.uuid,
          sAccount: localStorage.getItem("loginName"),
        },
      }).then((res) => {
        console.log(res.data.Msg);
        if (res.data.Msg == "操作失败:时间间隔不足3秒") {
          Toast.fail(res.data.Msg);
        } else if (res.data.Msg == "指令发送失败") {
          Toast.fail(res.data.Msg);
        } else {
          Toast.success(res.data.Msg);
        }
        this.FrequencySettingList = null;
        this.isFrequencySetting = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
table tr:nth-child(2) td {
  width: 53%;
}

.box {
  display: flex;
  align-items: center;
}

.cut {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  display: flex;
  justify-content: space-around;

  .left {
    width: 50px;
    height: 50px;
    font-size: 45px;
    color: rgba(0, 0, 0);
  }

  .right {
    width: 50px;
    height: 50px;
    font-size: 45px;
  }
}

.homes {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/img/PumpstationMonitor/pumpstationMonitor_waterDrop.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  z-index: -100;
}

.home {
  .nav {
    height: 10vh;
    background-color: rgb(87, 143, 254);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    .link {
      color: white;
    }

    span {
      margin-left: 10px;
      font-size: 20px;
      color: white;
    }
  }

  .main {
    .top {
      display: flex;
      justify-content: space-around;

      .search {
        display: block;
        width: 100%;
        height: 50px;
        border: none;
        border-radius: 15px;
        padding-left: 15px;
      }
    }

    h2 {
      height: 50px;
      line-height: 50px;
    }

    .somes {
      width: 100vw;
      box-sizing: border-box;
      // width: 390px;
      display: -webkit-box;
      overflow: scroll;

      .contain {
        box-sizing: border-box;
        width: 100%;

        .content {
          padding: 0 10px;
          box-sizing: border-box;

          table {
            width: 100%;
            border: 1px solid grey;
            border-radius: 10px;
            padding: 0 8px;
            margin: 15px 0;

            tr {
              height: 30px;

              .green {
                width: 20px;
                height: 20px;
                background-color: #00bfb0;
                border-radius: 50%;
              }

              .grey {
                width: 20px;
                height: 20px;
                background-color: #6e6e6e;
                border-radius: 50%;
              }

              .yellow {
                width: 20px;
                height: 20px;
                background-color: #ff8300;
                border-radius: 50%;
              }

              td {
                height: 30px;
                font-size: 16px;
                text-align: left;
              }

              .Remote {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }
        }

        .header {
          height: 80px;
          width: 100%;
          background-color: #01c1b2;
          position: relative;

          p {
            color: #fff;
            text-align: center;
            font-size: 16px;
          }

          h3 {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 22px;
            padding-top: 5px;
            margin: 0;
          }

          ul {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 100%;
            width: 80px;

            li {
              height: 50%;
              position: relative;

              button {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 60%;
                display: block;
                background-color: #fff;
                border: none;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.link {
  color: white;
  position: relative;

  .option {
    width: 75px;
    height: 80px;
    position: absolute;
    // z-index: 1001;
    right: 60px;
    top: 20px;

    .van-popup {
      background: #5690fe;
    }

    ul {
      position: absolute;
      width: 100%;
      z-index: 1000;
      height: 100%;

      a {
        color: #fff;
        font-size: 16px;
        margin-left: 5px;
        display: block;
        line-height: 25px;
      }

      li {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #f5f5f5;
        padding-left: 20px;

        img {
          width: 20px;
          vertical-align: sub;
        }

        a {
          color: #fff;
          line-height: 25px;
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }

    .lsit {
      width: 125%;
      height: 150%;
      position: absolute;
      top: 70px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.select {
  width: 100%;
  border-radius: 20px;
}

/deep/.van-dropdown-menu__item {
  border-radius: 10px;
}

/deep/.van-dropdown-menu__bar {
  border-radius: 15px;
  height: 60px;
}
</style>
