<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      alarm: null,
      fault: null,
    };
  },

  created() {
    this.$router.onReady(() => {
      if (this.$route.path == "/" || this.$route.path == "/login") {
        console.log("ws不执行");
      } else {
        this.initalarm();
        this.initfault();
      }
    });
  },

  beforeDestroy() {
    this.alarm.close();
    this.fault.close();
  },
  watch: {
    $route(to, from) {
      // 监听路由变化, 实现类似 小程序的 onShow 事件
      if (from.path === "/login" && to.path != "/") {
        !this.alarm && this.initalarm();
        !this.fault && this.initfault();
      }
      if (to.path === "/login" || to.path === "/") {
        this.alarm && this.alarm.close();
        this.fault && this.fault.close();
      }
    },
  },

  methods: {
    initalarm() {
      this.alarm = new WebSocket("wss://pumpwebsocketlan.gbhjt.com:4201"); //报警
      this.alarm.onmessage = this.handleWsMessagealarm;
      this.alarm.onopen = this.handleWsOpenalarm;
      this.alarm.onerror = this.handleWsErroralarm;
      this.alarm.onclose = this.handleWsClosealarm;
    },
    initfault() {
      this.fault = new WebSocket("wss://pumpwebsocketlan.gbhjt.com:4202"); //故障
      this.fault.onmessage = this.handleWsMessagefault;
      this.fault.onopen = this.handleWsOpenfault;
      this.fault.onerror = this.handleWsErrorfault;
      this.fault.onclose = this.handleWsClosefault;
    },

    handleWsOpenalarm(e) {
      console.log("打开alarm");
      this.alarm.send(
        JSON.stringify({
          loginName: localStorage.getItem("loginName"),
          companyID: localStorage.getItem("companyId"),
          type: "alarm",
        })
      );
    },
    handleWsClosealarm() {
      console.log("关闭alarm");
    },
    handleWsErroralarm() {
      console.log("错误alarm");
      this.initalarm();
    },
    handleWsMessagealarm(e) {
      const mess = JSON.parse(e.data);
      mess.forEach((item) => {
        this.$message({
          message: item.pumpStationNum + "--" + item.pumpsNum + item.faultName,
          type: "error",
          duration: 4000,
        });
      });
    },

    handleWsOpenfault(e) {
      console.log("打开fault");
      this.fault.send(
        JSON.stringify({
          loginName: localStorage.getItem("loginName"),
          companyID: localStorage.getItem("companyId"),
          type: "fault",
        })
      );
    },
    handleWsClosefault() {
      console.log("关闭fault");
    },
    handleWsErrorfault() {
      console.log("错误fault");
      this.initfault();
    },
    handleWsMessagefault(e) {
      const mess1 = JSON.parse(e.data);
      mess1.forEach((item) => {
        this.$message({
          message: item.pumpStationNum + item.pumpsNum + item.faultName,
          type: "warning",
          duration: 4000,
        });
      });
    },
  }
};
</script>

<style lang="less">
</style>
