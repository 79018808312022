<template>
  <div class="login">
    <div class="nav">
      <h1>账号密码登录</h1>
      <router-link to="/register" class="register">注册</router-link>
    </div>
    <div class="main">
      <van-form :model="loginForm" @submit="submit">
        <van-field
          v-model="loginForm.sAccount"
          name="用户名"
          left-icon="manager"
          :rules="[{ required: true, message: '请填写用户名' }]"
          placeholder="请输入用户名"
        />
        <van-field
          v-model="loginForm.pwd"
          name="密码"
          left-icon="lock"
          :right-icon="show"
          @click-right-icon="cut"
          :type="typepass"
          :rules="[{ required: true, message: '请填写密码' }]"
          placeholder="请输入密码"
        />
        <div
          style="
            width: 100%;
            height: 45px;
            text-align: right;
            box-sizing: border-box;
            position: relative;
          "
        >
          <router-link to="/reset" class="pass">忘记密码？</router-link>
        </div>
        <div style="margin: 16px">
          <van-button round block type="info">登录</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { login } from "@/api/api.js";
export default {
  data() {
    return {
      loginForm: {
        sAccount: "", // 账号
        pwd: "", // 密码
        action: "login",
      },
      show: "eye", // 密码显示隐藏
      typepass: "password",
    };
  },
  // created() {
  //   this.bj.close();
  //   this.gz.close();
  // },
  methods: {
    // 显示隐藏密码
    cut(e) {
      if (e.target._prevClass === "van-icon van-icon-eye") {
        this.show = "browsing-history";
        this.typepass = "text";
      } else {
        this.show = "eye";
        this.typepass = "password";
      }
    },
    // 登录请求
    submit(value) {
        let that = this;

      if (value) {
        Toast.loading({
          duration: 0, // 展示时长为0不会关闭
          forbidClick: true,
          message: "加载中...",
        });
        login({
          data: this.loginForm,
        })
          .then((res) => {
            if (res.data.Code === 1) {
              // 登录成功
              Toast.clear();
              Toast({
                message: "登录成功",
                position: "top",
              });

              localStorage.setItem("companyId", res.data.uCompanyListID);

              sessionStorage.setItem(`isAllow`, true);
              
              let userNamelocal = localStorage.getItem(`loginName`),
                  passWordLocal = localStorage.getItem(`password`);

                  if(!(userNamelocal && userNamelocal == that.loginForm.sAccount)){
                      localStorage.setItem("loginName", this.loginForm.sAccount);
                  }

                  if(!(passWordLocal && passWordLocal == that.loginForm.pwd)){
                      localStorage.setItem("password", this.loginForm.pwd);
                  }

              this.$router.push("/home");
            } else {
              // 登录失败
              Toast.clear();
              Toast({
                message: res.data.Dis,
                position: "top",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //判断是否微信打开
    isWeChat(){

        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf(`micromessenger`) != -1;

    }

  },

  beforeRouteEnter(to, from , next){

    if(from.name != `/` || from.name != `login`){
        sessionStorage.getItem(`isAllow`) && sessionStorage.removeItem(`isAllow`)
    }

    next()
  },

  beforeRouteLeave(to, from, next){
    
    sessionStorage.getItem(`isAllow`) && localStorage.getItem(`loginName`) && next();

  },

  created(){

    let that = this;

    let userNamelocal = localStorage.getItem(`loginName`),
        passWordLocal = localStorage.getItem(`password`);

        userNamelocal && (that.loginForm.sAccount = userNamelocal);
        passWordLocal && (that.loginForm.pwd = passWordLocal);

  }
  
};
</script>

<style lang="less" scoped>
.login {
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10% 0;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }

    .register {
      font-size: 18px;
      color: black;
    }
  }

  .main {
    padding: 20% 0;

    .van-cell::after {
      border-bottom: 2px solid black;
    }

    .van-cell {
      margin-top: 25px;
    }

    .pass {
      position: absolute;
      display: block;
      color: black;
      width: 100px;
      right: 10px;
      top: 50%;
      padding: 10px 0;
      transform: translateY(-50%);
    }
  }
}
</style>
