import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginComponent from '@/views/login/index';
import RegisterComponent from '@/views/register/index';
import HomeComponent from '@/views/home/index';
import WaterComponent from '@/views/water/index';
import PwdmComponent from '@/views/pwdm/index';
import ResetComponent from '@/views/reset/index';
import EchartsComponent from '@/views/Echarts/index';
import ErrorlistComponent from '@/views/errorList/index';
import PoliceListComponent from '@/views/policeList/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent//() => import('@/views/login/index')
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterComponent//() => import('@/views/register/index')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeComponent//() => import('@/views/home/index')
  },
  {
    path: '/water',
    name: 'water',
    component: WaterComponent//() => import('@/views/water/index')
  },
  {
    path: '/pwdm',
    name: 'pwdm',
    component: PwdmComponent//() => import('@/views/pwdm/index')
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetComponent//() => import('@/views/reset/index')
  },
  {
    path: '/echarts',
    name: 'Echarts',
    component: EchartsComponent//() => import('@/views/Echarts/index')
  },
  {
    path: '/errorlist',
    name: 'errorlist',
    component: ErrorlistComponent//() => import('@/views/errorList/index')
  },
  {
    path: '/policelist',
    name: 'policelist',
    component: PoliceListComponent//() => import('@/views/policeList/index')
  }
]

//解决路由重复跳转一个的问题
//缓存原型上的push函数
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// VueRouter.prototype.push = function (location, onComplete, onAbort) {
//     // 给原型对象上的push指定新函数函数
//     if( onComplete === undefined && onAbort === undefined ){
//         return originalPush.call(this, location, onComplete, onAbort).catch( () => {} )
//     } else {
//         originalPush.call(this, location, onComplete, onAbort)
//     }

// }

// //originalReplace 同时处理
// Vue.prototype.replace = (location, onComplete, onAbort) => {

//     if(onComplete === undefined && onAbort === undefined){
//         return originalReplace.call(this, location, onComplete, onAbort).catch( () => {} )
//     } else {
//         originalReplace.call(this, location, onComplete, onAbort)
//     }

// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 全局路由前置守卫
// router.beforeEach((to, from, next) => {

//   if (to.path === '/login') return next()
//   if (to.path === '/register') return next()
//   if (to.path === '/reset') return next()
//   if (!localStorage.getItem('loginName')) {
//     if (to.path === '/') {
//       return next()
//     }
//     return next('/login')
//   }
//   next()
// })

export default router
