import axios from 'axios'

const request = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  baseURL: 'https://pumpajaxlan.gbhjt.com/api',
  timeout: 5000
})

export default request
