<template>
  <div class="login">
    <van-nav-bar title="重置密码" @nav-bar-title-font-size="20" left-text="返回" left-arrow @click-left="$router.go(-1)" />
    <div class="main">
      <van-form :model="pwdm" ref="passRef" @submit="submit">
        <div class="nav-to">
          <van-field v-model="pwdm.phone" class="reset" type="number" name="手机号" placeholder="请输入手机号" />
        </div>
        <div class="box">
          <van-field v-model="pwdm.yzm" type="text" name="验证码" placeholder="请输入验证码" />
          <button type="button" class="gain" @click="getCode">获取验证码</button>
        </div>
        <van-field v-model="pwdm.newPassword" type="password" name="新密码" placeholder="输入新密码" />
        <div style="margin: 16px;">
          <van-button round block type="info">确认</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      pwdm: {
        phone: '',
        yzm: '',
        newPassword: ''
      }
    }
  },
  methods: {
    // 发送验证码
    getCode () {
      console.log(111)
    },
    // 提交表单
    submit () {
      Toast.loading({
        duration: 0, // 展示时长为0不会关闭
        forbidClick: true,
        message: '加载中...'
      })
      setInterval(function () {
        Toast.success('更换成功')
        Toast.clear()
      }, 3000)
      this.pwdm = {}
    }
  }
}
</script>

<style lang="less" scoped>
.van-cell::after {
  border-bottom: 2px solid black;
}

.box {
  position: relative;

  .gain {
    position: absolute;
    border: none;
    background-color: white;
    right: 10px;
    top: 10px;
  }
}

/deep/.van-field__control {
  padding-bottom: 5px;
}

.login {
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10% 0;

    .register {
      font-size: 18px;
      color: black;
    }
  }

  .main {
    padding: 20% 0;

    .van-cell {
      margin-top: 25px;
    }
  }
}

.van-button {
  margin-top: 50px;
}

/deep/.van-nav-bar__content {
  height: 100px;
}

.van-cell--borderless::after,
.van-cell:last-child::after {
  display: block;
}
</style>
