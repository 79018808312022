import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import * as Echarts from 'echarts';
import './assets/font/iconfont.css'
import VueTouch from 'vue-touch'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(Element, { size: 'small', zIndex: 3000 });

Vue.use(ElementUI);

Vue.use(VueTouch, { name: 'v-touch' })
VueTouch.config.swipe = {
  threshold: 50 // 设置左右滑动的距离
}

Vue.use(vant)
Vue.prototype.$echarts = Echarts // 挂载到Vue实例上面
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
