import request from './request.js'

// 登录
export function login (data) {
  return request({
    method: 'post',
    url: '/user.ashx',
    data: data.data
  })
}

// 密码管理
export function pwd (data) {
  return request({
    method: 'post',
    url: '/user.ashx',
    data: data.data
  })
}

// 水厂接口 /api/getInfoTopc.ashx
export function water (data) {
  return request({
    method: 'post',
    url: '/getInfoTopc.ashx',
    data: data.data
  })
}

// 泵站列表
export function standList (data) {
  return request({
    method: 'post',
    url: '/getInfoTopc.ashx',
    data: data.data
  })
}

// 水泵列表
export function waterList (data) {
  return request({
    method: 'post',
    url: '/getInfoTopc.ashx',
    data: data.data
  })
}

// 故障未处理
export function errorList (data) {
  return request({
    method: 'post',
    url: '/getAlarmAndFaultMsg.ashx',
    data: data.data
  })
}

// 故障已处理
export function errorYList (data) {
  return request({
    method: 'post',
    url: '/getAlarmAndFaultMsg.ashx',
    data: data.data
  })
}

// 报警列表未处理
export function policeWList (data) {
  return request({
    method: 'post',
    url: '/getAlarmAndFaultMsg.ashx',
    data: data.data
  })
}

// 报警列表已处理
export function policeYList (data) {
  return request({
    method: 'post',
    url: '/getAlarmAndFaultMsg.ashx',
    data: data.data
  })
}

// 手自动切换
export function automaticApi (data) {
  return request({
    method: 'post',
    url: '/EquipmentControl.ashx',
    data: data.data
  })
}

// 远程启停
export function remoteApi (data) {
  return request({
    url: '/EquipmentControl.ashx',
    method: 'post',
    data: data.data
  })
}

// Echart接口
export function chartApi (data) {
  return request({
    url: '/getReport.ashx',
    method: 'post',
    data: data.data
  })
}

// 频率设定
export function pinLvApi (data) {
  return request({
    url: '/EquipmentControl.ashx',
    method: 'post',
    data: data.data
  })
}

// 压力设定
// export function yaLiApi (data) {
//   return request({
//     url: '/EquipmentControl.ashx',
//     method: 'post',
//     data: data.data
//   })
// }

//水压设定
const updateWaterOutletPressureSettingApi = ({
    uuid = ``,
    sValue = ``,//设置水位限定值
    sAccount = ``
}) => {
    return request({
        url: '/EquipmentControl.ashx',
        method: 'post',
        data: {
            action: `pumpStationWaterPressureSetting`,
            uuid,
            sValue,
            sAccount
        }
    })
}

//水位设定
const updateWaterLevelApi = ({
    uuid = ``,
    sProp= ``,
    sValue = ``,//设置水位限定值
    sAccount = ``
}) => {
    return request({
        url: '/EquipmentControl.ashx',
        method: 'post',
        data: {
            action: `LiquidLevelSetting`,
            uuid,
            sProp,
            sValue,
            sAccount
        }
    })
}

//轮换时间
const updateRotationTimeApi = ({
    uuid = ``,
    sValue = ``,//设定时间值
    sAccount = ``
}) => {
    return request({
        url: '/EquipmentControl.ashx',
        method: 'post',
        data: {
            action: `RotationTime`,
            uuid,
            sValue,
            sAccount
        }
    })
} 

export {
    updateWaterOutletPressureSettingApi,
    updateWaterLevelApi,
    updateRotationTimeApi
};
