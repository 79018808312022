<template>
    <div class="home">
        <div class="nav">
            <div class="box">
                <van-icon name="user-circle-o" size="50px" color="white" /><span>{{
                    loginName
                }}</span>
            </div>
            <div class="link">
                <van-icon name="wap-nav" size="20px" @click="lists" />
                <div class="option">
                    <van-popup v-model="isShow" position="top" :style="{ height: '230px' }">
                        <ul>
                            <router-link to="/policelist">
                                <li>
                                    <img src="@/assets/images/img/fontImgs/bj.png" alt="" />报警列表
                                </li>
                            </router-link>

                            <router-link to="/errorlist">
                                <li>
                                    <img src="@/assets/images/img/fontImgs/gz.png" alt="" />故障列表
                                </li>
                            </router-link>

                            <router-link to="/echarts">
                                <li>
                                    <img src="@/assets/images/img/fontImgs/sz.png" alt="" />运行报表
                                </li>
                            </router-link>

                            <router-link to="/pwdm">
                                <li>
                                    <img src="@/assets/images/img/fontImgs/mm.png" alt="" />修改密码
                                </li>
                            </router-link>

                            <a href="" @click="quit">
                                <li>
                                    <img src="@/assets/images/img/fontImgs/fh.png" alt="" />安全退出
                                </li>
                            </a>
                        </ul>
                    </van-popup>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="top">
                <van-dropdown-menu class="select">
                    <van-dropdown-item v-model="value" :options="option" @click.native="getVlaue" />
                </van-dropdown-menu>
            </div>
            <van-swipe :loop="false" :height="750" style="z-index: 100">
                <!-- 泵站每一项 -->
                <van-swipe-item v-for="(item, index) in list" :key="item.uuid">
                    <div class="somes">
                        <div class="contains">
                            <div class="piece">
                                <div class="header">
                                    <p>{{ item.title }}</p>
                                    <ul>
                                        <li><button @click="look(index)">查看</button></li>
                                    </ul>
                                </div>
                                <div class="content">

                                    <table>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-hulianwang1"></span> 联网状态
                                            </td>
                                            <td>{{ item.info.netWorkingStatus }}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-shuijinbaojing"></span>
                                                缺水报警
                                            </td>
                                            <td>
                                                <div :class="item.info.waterShortageAlarm"></div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-baojing01"></span> 故障报警
                                            </td>
                                            <td>
                                                <div :class="item.info.faultAlarm"></div>
                                            </td>
                                        </tr>
                                    </table>

                                    <table>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-dixiashuiwei"></span>
                                                取水水位
                                            </td>
                                            <td>{{ item.info.waterIntakeLevel }}m</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-shuiwei3"></span> 出水水位
                                            </td>
                                            <td>{{ item.info.outletWaterLevel }}m</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-kongxishuiyaliji"></span>
                                                出水压力
                                            </td>
                                            <td>{{ item.info.waterOutletPressure }}MPa</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-hehuliuliangjiancedian"></span>
                                                出水流量
                                            </td>
                                            <td>{{ item.info.effluentFlow }}m³/h</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-dianya4"></span> 供电电压
                                            </td>
                                            <td>{{ item.info.voltage }}V</td>
                                        </tr>
                                    </table>

                                    <table>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-shuizhiyulv"></span> 出水余氯
                                            </td>
                                            <td>{{ item.info.residualChlorine }}mg/L</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-zhuodu1"></span> 出水浊度
                                            </td>
                                            <td>{{ item.info.turbidity }}mg/L</td>
                                        </tr>
                                        <tr>
                                            <td><span class="iconfont icon-PHzhi"></span> 出水PH</td>
                                            <td>{{ item.info.PH }}mol/L</td>
                                        </tr>
                                    </table>

                                    <table>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-wendu2"></span> 泵站温度
                                            </td>
                                            <td>{{ item.info.pumpStationTemperature }}℃</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-daqishidu"></span> 泵站湿度
                                            </td>
                                            <td>{{ item.info.pumpStationHumidity }}%</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-yanwubaojingqi3x"></span>
                                                泵站烟雾
                                            </td>
                                            <td>{{ item.info.pumpStationSmoke }}mg/m³</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span class="iconfont icon-shuijin_jinshui"></span>
                                                泵站浸水
                                            </td>
                                            <td>{{ item.info.pumpStationImmersion }}</td>
                                        </tr>
                                    </table>
                                    <table>
                                        <!-- <tr
                                            style="display: flex;
                                                align-items: center;
                                                width: 100%;
                                                justify-content: space-between;
                                                padding: 5px 0;
                                            "
                                            >
                                            <td style="display: flex; align-items: center">
                                                <img
                                                style="width: 17px; height: 17px; margin-right: 4px"
                                                src="../../assets/images/set.png"
                                                />
                                                <span style="margin-bottom: 2px">压力设定</span>
                                            </td>
                                            <td style="display: flex; align-items: center">
                                                <input
                                                type="text"
                                                @focus="changeIsWaterOutletPressureSetting(1)"
                                                v-if="isWaterOutletPressureSetting"
                                                v-model="item.info.WaterOutletPressureSetting"
                                                style="width: 60px; margin-left: 100px"
                                                />
                                                <input
                                                type="text"
                                                v-activeFocus
                                                @blur="changeIsWaterOutletPressureSetting(0)"
                                                v-else
                                                v-model="WaterOutletPressureSettingList"
                                                style="width: 60px; margin-left: 100px"
                                                />
                                            </td>
                                            <td style="display: flex; align-items: center">
                                                <button @click="getYaLi(item)" style="font-size: 14px">
                                                设置
                                                </button>
                                            </td>
                                        </tr> -->
                                        <!-- pumpStationSetOPtions -->
                                        <template v-for="(valInfo, keyInfo, indexInfo ) in item.info">

                                            <template v-if="pumpStationSetOPtions.find(F => F.prop == keyInfo)">

                                                <tr :key="keyInfo" style="display: flex;
                                                    align-items: center;
                                                    width: 100%;
                                                    justify-content: space-between;
                                                    padding: 5px 0;">
                                                    
                                                    <td style="display: flex; align-items: center">
                                                        <i style="width: 17px; height: 17px; margin-right: 4px"
                                                            :class="pumpStationSetOPtions.find(F => F.prop == keyInfo).icon"></i>
                                                        <span style="margin-bottom: 2px">{{ pumpStationSetOPtions.find(F =>
                                                            F.prop == keyInfo).label }}</span>
                                                    </td>
                                                    
                                                    <td  style="display: flex;justify-content: flex-end;align-items: center">
                                                        <!-- 显示 -->

                                                        <input ref="inpuFocus"
                                                            v-show="!pumpStationSetOPtions.find(F => F.prop == keyInfo).showState"
                                                            :type="pumpStationSetOPtions.find(F => F.prop == keyInfo).inputType"
                                                            :min="pumpStationSetOPtions.find(F => F.prop == keyInfo).min || 0"
                                                            :step="pumpStationSetOPtions.find(F => F.prop == keyInfo).step || 1"
                                                            v-model="item.info[keyInfo]"
                                                            style="width: 50px;"
                                                            @focus="() => { let optionItemTemp = pumpStationSetOPtions.find(F => F.prop == keyInfo); optionItemTemp.focusFn({ opt: optionItemTemp, switchProp: `showState`, tempStoreProp: `tempStoreVal`, originVal: item.info[keyInfo] }) }" />
                                                        <!-- 输入 -->
                                                        <input ref="inpuBlur"
                                                            v-show="pumpStationSetOPtions.find(F => F.prop == keyInfo).showState"
                                                            v-activeFocus
                                                            :type="pumpStationSetOPtions.find(F => F.prop == keyInfo).inputType"
                                                            :min="pumpStationSetOPtions.find(F => F.prop == keyInfo).min || 0"
                                                            :step="pumpStationSetOPtions.find(F => F.prop == keyInfo).step || 1"
                                                            v-model="pumpStationSetOPtions.find(F => F.prop == keyInfo).tempStoreVal"
                                                            style="width: 50px;"
                                                            @blur="() => { let optionItemTemp = pumpStationSetOPtions.find(F => F.prop == keyInfo); optionItemTemp.blurFn({ opt: optionItemTemp, switchProp: `showState`, tempStoreProp: `tempStoreVal` }) }" />

                                                        <span style="width: 40px;text-align: center;overflow: hidden;text-overflow: ellipsis;white-space:nowrap;">{{pumpStationSetOPtions.find(F => F.prop == keyInfo).util}}</span>

                                                        <template v-if="pumpStationSetOPtions.find(F => F.prop == keyInfo).click">

                                                            <button
                                                                style="margin-left: 3px;font-size: 14px"
                                                                @click="() => { let optionItemTemp = pumpStationSetOPtions.find(F => F.prop == keyInfo); optionItemTemp.click.clickMethod({ uuid: item.uuid, prop: keyInfo, value: optionItemTemp.tempStoreVal }) }">
                                                                {{ pumpStationSetOPtions.find(F => F.prop ==
                                                                    keyInfo).click.label }}
                                                            </button>

                                                        </template>

                                                    </td>

                                                </tr>

                                            </template>

                                        </template>

                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>

<script>
import { water, standList, /* yaLiApi, */updateWaterOutletPressureSettingApi, updateWaterLevelApi, updateRotationTimeApi } from "@/api/api.js";
import { Toast } from "vant";
import { deepCopy, deepAssignment } from '@/utils/normalUtil';
import { write } from "fs";
export default {
    name: `home`,
    //指令
    directives: {
        activeFocus: {
            inserted(el) {
                el.focus()
            }
        }
    },

    data() {
        return {
            current: 0,
            loginName: "", // 登录名
            value: 0, // 用户选择的哪个水厂
            num: 0,
            stationsNumber: 0, // 水厂数量
            isShow: false,
            list: [], // 泵站数据
            option: [], // 水厂数据
            yaliData: 0,
            WaterOutletPressureSettingList: null,
            isWaterOutletPressureSetting: true,
            ws: null,
            /* 
              prop:与后台对应的数据属性
              icon:icon图标
              inputType：输入框的类型
              showState: 显示状态
              tempStoreVal: 临时存储的值
              min: 输入框输入的最小值 (number)
              step: 步长/精度(number)
              click: button按钮的click事件对象
              focusFn:输入框聚焦方法
              blurFn:输入框失焦方法
            */
            pumpStationSetOPtions: [
                { prop: `WaterOutletPressureSetting`, icon: `iconfont icon-icon_shuiyaji`, inputType: `number`, showState: false, tempStoreVal: ``,util: `MPa`, min: '0.0', step: '0.1', label: `水压设定`, click: { label: `设置`, clickMethod: this.updateWaterOutletPressureMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel0`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `停泵液位`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel1`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启一台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel2`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启二台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel3`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启三台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel4`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启四台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel5`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启五台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `waterLevel6`, icon: `iconfont icon-shuiwei3`, inputType: `number`, showState: false, tempStoreVal: ``,util: `M`, min: '0.0', step: '0.1', label: `启六台泵液位设定`, click: { label: `设置`, clickMethod: this.updateWaterLevelMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod },
                { prop: `rotationTime`, icon: `iconfont icon-dianyabiao`, inputType: `number`,showState: false, tempStoreVal: ``,util: `H`, min: '0.0', step: '0.1', label: `轮换时间`, click: { label: `设置`, clickMethod: this.updateRotatalTimeMethods }, focusFn: this.inputSwitchFocusMethod, blurFn: this.inputSwitchBlurMethod }
            ]
        };
    },
    created() {
        // 欢迎
        this.loginName =
            "欢迎您" + " " + localStorage.getItem("loginName") + " " + "!";
        // 水厂数据s
        this.getWaters();
        this.initWebSocket();
    },
    beforeDestroy() {
        this.ws.close();
    },
    methods: {
        /* input框聚焦后的操作*/
        inputSwitchFocusMethod({
            opt = null,
            switchProp = ``,
            tempStoreProp = ``,
            originVal = ``
        }) {
            let that = this;

            if (!opt) return;
            //置反
            opt.hasOwnProperty(switchProp) && (opt[switchProp] = !opt[switchProp]);
            //赋值
            opt.hasOwnProperty(tempStoreProp) && (opt[tempStoreProp] = originVal);
        },

        /* input框失焦后的操作*/
        inputSwitchBlurMethod({
            opt = null,
            switchProp = ``,
            tempStoreProp = ``
        }) {
            let that = this;

            if (!opt) return;
            //置反
            opt.hasOwnProperty(switchProp) && (opt[switchProp] = !opt[switchProp]);

        },

        initWebSocket() {
            this.ws = new WebSocket("wss://pumpwebsocketlan.gbhjt.com:4203");
            this.ws.onmessage = this.handleWsMessage;
            this.ws.onopen = this.handleWsOpen;
            this.ws.onerror = this.handleWsError;
            this.ws.onclose = this.handleWsClose;
        },

        handleWsOpen(e) {
            console.log("open1");
            this.ws.send(
                JSON.stringify({
                    loginName: localStorage.getItem("loginName"),
                    puuid: localStorage.getItem("waterId"),
                    type: "pumpStation",
                })
            );
        },

        handleWsClose(e) {
            console.log("close1");

            // ws = new WebSocket("ws://pumpwebsocketlan.gbhjt.com:4103");
            // this.ws.addEventListener("open", this.handleWsOpen.bind(this), false);
        },
        handleWsError(e) {
            console.log("error", e);
            Toast({
                message: "泵站数据获取失败",
                position: "top",
            });
            // this.initWebSocket();
        },
        handleWsMessage(e) {
            const wsList = JSON.parse(e.data);
            //   if (wsList.data.length > 0) {
            //     for (let i = 0; i < wsList.data.length; i++) {
            //       for (let j = 0; j < this.list.length; j++) {
            //         if (wsList.data[i].uuid === this.list[j].uuid) {
            //           for (const k in wsList.data[i].info) {
            //             if (wsList.data[i].info[k]) {
            //               this.list[j].info[k] = wsList.data[i].info[k];
            //             }
            //           }
            //         }
            //       }
            //     }
            //   }

            const assignmentFn = ({ assigned, assigning, prop, oldVal, newVal }) => {

                let $assigned, $assigning;

                //递归赋值,递归回调的处理
                if (!oldVal && !newVal && Array.isArray(assigned) && Array.isArray(assigning) || Array.isArray(oldVal) && Array.isArray(newVal)) {
                    //第一次
                    if (!oldVal && !newVal && Array.isArray(assigned) && Array.isArray(assigning)) {
                        $assigned = assigned;
                        $assigning = assigning;
                    } else {//第n次子
                        $assigned = oldVal;
                        $assigning = newVal;
                    }

                    $assigning.forEach((item, index) => {

                        let finder = $assigned.find(F => F.uuid == item.uuid);

                        deepAssignment(finder, item, assignmentFn)

                    })

                } else {//基本数据类型string,number,function,boolean或不统一的数据结构

                    //排除不统一的对象数据结构赋值
                    if (typeof newVal != 'object') {
                        assigned[prop] = newVal
                    }

                }

            }

            deepAssignment(this.list, wsList.data, assignmentFn);

            this.list.forEach((item) => {
                // 联网状态转换
                if (
                    item.info.netWorkingStatus === 0 ||
                    item.info.netWorkingStatus === "0"
                ) {
                    item.info.netWorkingStatus = "离线";
                } else if (
                    item.info.netWorkingStatus === 1 ||
                    item.info.netWorkingStatus === "1"
                ) {
                    item.info.netWorkingStatus = "正常";
                } else {
                }
                // 缺水报警转换
                if (
                    item.info.waterShortageAlarm === 0 ||
                    item.info.waterShortageAlarm === "0"
                ) {
                    item.info.waterShortageAlarm = "active";
                } else if (
                    item.info.waterShortageAlarm === 1 ||
                    item.info.waterShortageAlarm === "1"
                ) {
                    item.info.waterShortageAlarm = "status";
                } else if (
                    item.info.waterShortageAlarm === 2 ||
                    item.info.waterShortageAlarm === "2"
                ) {
                    item.info.waterShortageAlarm = "guzhang";
                } else {
                }
                // 故障报警转换
                if (item.info.faultAlarm === 0 || item.info.faultAlarm === "0") {
                    item.info.faultAlarm = "active";
                } else if (item.info.faultAlarm === 1 || item.info.faultAlarm === "1") {
                    item.info.faultAlarm = "status";
                } else if (item.info.faultAlarm === 2 || item.info.faultAlarm === "2") {
                    item.info.faultAlarm = "guzhang";
                } else {
                }
                // 泵站浸水转换
                if (
                    item.info.pumpStationImmersion === "0" ||
                    item.info.pumpStationImmersion === 0
                ) {
                    item.info.pumpStationImmersion = "正常";
                } else if (
                    item.info.pumpStationImmersion === "1" ||
                    item.info.pumpStationImmersion === 1
                ) {
                    item.info.pumpStationImmersion = "浸水";
                } else {
                }
            });

            // console.log(this.list);
        },
        // 获取水厂列表
        getWaters() {
            Toast.loading({
                duration: 0, // 展示时长为0不会关闭
                forbidClick: true,
                message: "加载中...",
            });
            water({
                data: {
                    action: "getPumpstationAddress",
                    search: "",
                    sAccount: localStorage.getItem("loginName"),
                },
            }).then((res) => {
                // 水厂数量
                this.stationsNumber = res.data.data.length;
                // 先存一个水厂id页面默认渲染第一个水厂
                localStorage.setItem("waterId", res.data.data[0].uuid);
                // 选择水厂
                for (let i = 0; i < res.data.data.length; i++) {
                    const person = {
                        text: res.data.data[i].title,
                        value: i,
                        uuid: res.data.data[i].uuid,
                    };
                    this.option.push(person);
                }
                this.getStands();
            });
        },
        // 泵站列表数据
        getStands() {
            standList({
                data: {
                    action: "getPumpstationListInfo",
                    puuid: localStorage.getItem("waterId"), // localStorage.getItem('waterId')
                    uuid: "all",
                    run: true,
                    stop: true,
                    fault: false,
                    sAccount: localStorage.getItem("loginName"),
                },
            }).then((res) => {
                // 操作深拷贝的数据
                this.list = [...res.data.data];
                console.log(this.list);

                this.list.forEach((item) => {
                    // 联网状态转换
                    if (
                        item.info.netWorkingStatus === 0 ||
                        item.info.netWorkingStatus === "0"
                    ) {
                        item.info.netWorkingStatus = "离线";
                    } else if (
                        item.info.netWorkingStatus === 1 ||
                        item.info.netWorkingStatus === "1"
                    ) {
                        item.info.netWorkingStatus = "正常";
                    } else {
                    }
                    // 缺水报警转换
                    if (
                        item.info.waterShortageAlarm === 0 ||
                        item.info.waterShortageAlarm === "0"
                    ) {
                        item.info.waterShortageAlarm = "active";
                    } else if (
                        item.info.waterShortageAlarm === 1 ||
                        item.info.waterShortageAlarm === "1"
                    ) {
                        item.info.waterShortageAlarm = "status";
                    } else if (
                        item.info.waterShortageAlarm === 2 ||
                        item.info.waterShortageAlarm === "2"
                    ) {
                        item.info.waterShortageAlarm = "guzhang";
                    } else {
                    }
                    // 故障报警转换
                    if (item.info.faultAlarm === 0 || item.info.faultAlarm === "0") {
                        item.info.faultAlarm = "active";
                    } else if (
                        item.info.faultAlarm === 1 ||
                        item.info.faultAlarm === "1"
                    ) {
                        item.info.faultAlarm = "status";
                    } else if (
                        item.info.faultAlarm === 2 ||
                        item.info.faultAlarm === "2"
                    ) {
                        item.info.faultAlarm = "guzhang";
                    } else {
                    }
                    // 泵站浸水转换
                    if (
                        item.info.pumpStationImmersion === "0" ||
                        item.info.pumpStationImmersion === 0
                    ) {
                        item.info.pumpStationImmersion = "正常";
                    } else if (
                        item.info.pumpStationImmersion === "1" ||
                        item.info.pumpStationImmersion === 1
                    ) {
                        item.info.pumpStationImmersion = "浸水";
                    } else {
                    }
                    Toast.clear();
                });
            });
        },

        lists() {
            this.num++;
            if (this.num === 1) {
                this.isShow = true;
            } else {
                this.isShow = false;
                this.num = 0;
            }
        },

        // 退出登录
        quit() {
            //localStorage.removeItem("userinfo");
            this.$router.push('/login')
        },

        // 水厂切换
        getVlaue() {
            // 保存选择的水厂id以便在选择水厂的时候发送axios参数
            localStorage.setItem("waterId", this.option[this.value].uuid);
            this.getStands();
            this.handleWsClose();
            this.handleWsOpen();
        },

        // 查看泵站的水泵
        look(index) {
            localStorage.setItem("staticPuid", this.list[index].uuid);
            this.$router.push({
                path: "/water",
                query: {
                    id: this.list[index].uuid,
                },
            });
        },
        // getYaLi(item) {
        //     if (!this.WaterOutletPressureSettingList) {
        //         this.WaterOutletPressureSettingList =
        //             item.info.WaterOutletPressureSetting;
        //     }
        //     yaLiApi({
        //         data: {
        //             action: "pumpStationWaterPressureSetting",
        //             sValue: this.WaterOutletPressureSettingList,
        //             // sValue: 123,
        //             uuid: item.uuid,
        //             sAccount: localStorage.getItem("loginName"),
        //         },
        //     }).then((res) => {
        //         if (res.data.Msg == "操作失败:时间间隔不足3秒") {
        //             Toast.fail(res.data.Msg);
        //         } else if (res.data.Msg == "指令发送失败") {
        //             Toast.fail(res.data.Msg);
        //         } else {
        //             Toast.success(res.data.Msg);
        //         }

        //         this.WaterOutletPressureSettingList = null;
        //         this.isWaterOutletPressureSetting = true;
        //     });
        // },
        // 压力设定输入框
        changeIsWaterOutletPressureSetting(i) {
            if (i == 1) {
                this.isWaterOutletPressureSetting = false;
            } else if (!this.WaterOutletPressureSettingList & (i == 0)) {
                this.isWaterOutletPressureSetting = true;
            }
        },
        //更新停放液位设定值的方法
        updateWaterLevelMethods({
            uuid = ``,
            prop = ``,
            value = ``
        }) {
            let that = this;

            updateWaterLevelApi({
                uuid,
                sProp: prop,
                sValue: value,
                sAccount: localStorage.getItem("loginName")
            }).then(res => {

                if (res.Code == 1) {
                    Toast.success(res.data.Msg);
                } else {
                    Toast.fail(res.data.Msg);
                }

            }).catch(err => {
                Toast.fail(`请求发送失败`);
             })
        },
        //更新轮转时间设定值的方法
        updateRotatalTimeMethods({
            uuid = ``,
            value = ``
        }) {
            let that = this;

            updateRotationTimeApi({
                uuid,
                sValue: value,
                sAccount: localStorage.getItem("loginName")
            }).then(res => {

                if (res.Code == 1) {
                    Toast.success(res.data.Msg);
                } else {
                    Toast.fail(res.data.Msg);
                }

            }).catch(err => {
                Toast.fail(`请求发送失败`);
            })
        },
        //更新水压设定的方法
        updateWaterOutletPressureMethods({
            uuid = ``,
            value = ``
        }) {
            let that = this;

            updateWaterOutletPressureSettingApi({
                uuid,
                sValue: value,
                sAccount: localStorage.getItem("loginName"),
            }).then((res) => {

                if (res.Code == 1) {
                    Toast.success(res.data.Msg);
                } else {
                    Toast.fail(res.data.Msg);
                }

            }).catch(err => {
                Toast.fail(`请求发送失败`);
            })
        }
        // getPolice () {
        //   alert('请进入到站点查看页面，在进行此操作')
        // },
        // getErr () {
        //   alert('请进入到站点查看页面，在进行此操作')
        // }
    },
};
</script>

<style lang="less" scoped>
table tr:nth-child(2) td {
    width: 70%;
}

.box {
    display: flex;
    align-items: center;
}

.cut {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;

    .left {
        width: 50px;
        height: 50px;
        font-size: 45px;
        color: rgba(0, 0, 0);
    }

    .right {
        width: 50px;
        height: 50px;
        font-size: 45px;
    }
}

.home {
    background-image: url("../../assets/images/img/PumpstationMonitor/pumpstationMonitor_waterDrop.png");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;

    .nav {
        height: 10vh;
        background-color: rgb(87, 143, 254);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;

        span {
            margin-left: 10px;
            font-size: 20px;
            color: white;
        }
    }

    .main {
        // height: 80vh;

        .top {
            display: flex;
            justify-content: space-around;

            .search {
                display: block;
                width: 100%;
                height: 50px;
                border: none;
                border-radius: 15px;
                padding-left: 15px;
            }
        }

        h2 {
            height: 50px;
            line-height: 50px;
        }

        .van-swipe-item {
            overflow: auto;

            .somes {
                width: 100vw;
                box-sizing: border-box;
                white-space: nowrap;
                // overflow: scroll;
                display: -webkit-box;

                .contains {
                    width: 100%;
                    box-sizing: border-box;

                    flex: none;

                    .content {
                        box-sizing: border-box;
                        padding: 0 10px .1px;

                        table {
                            width: 100%;
                            border: 1px solid grey;
                            border-radius: 10px;
                            padding: 0 8px;
                            margin: 15px 0;

                            tr {
                                height: 30px;

                                .status {
                                    width: 20px;
                                    height: 20px;
                                    background-color: #00bfb0;
                                    border-radius: 50%;
                                }

                                .active {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    background-color: #6e6e6e;
                                }

                                .guzhang {
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    background-color: #ff8300;
                                }

                                td {
                                    height: 30px;
                                    font-size: 16px;
                                    text-align: left;
                                }
                            }
                        }
                    }

                    .header {
                        display: flex;
                        height: 80px;
                        width: 100%;
                        background-color: #01c1b2;
                        position: relative;

                        p {
                            width: 100%;
                            text-align: center;
                            color: white;
                            font-size: 22px;
                        }

                        ul {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            height: 100%;
                            width: 80px;

                            li {
                                height: 50%;
                                position: relative;

                                button {
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;
                                    bottom: 0;
                                    margin: auto;
                                    width: 80px;
                                    height: 80%;
                                    display: block;
                                    background-color: #fff;
                                    border: none;
                                    font-size: 18px;
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

.link {
    color: white;
    position: relative;

    .option {
        width: 75px;
        height: 80px;
        position: absolute;
        // z-index: 1001;
        right: 60px;
        top: 20px;

        .van-popup {
            background: #5690fe;
        }

        ul {
            position: absolute;
            width: 100%;
            z-index: 1000;
            height: 100%;

            a {
                color: #fff;
                font-size: 16px;
                margin-left: 5px;
                display: block;
                line-height: 25px;
            }

            li {
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid #f5f5f5;
                padding-left: 20px;

                img {
                    width: 20px;
                    vertical-align: sub;
                }
            }
        }

        .lsit {
            width: 125%;
            height: 150%;
            position: absolute;
            top: 70px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.select {
    z-index: 1000;
    width: 100%;
    border-radius: 20px;
}

.home .main .somes .contains {
    height: 700px;
}

/deep/.van-dropdown-menu__item {
    border-radius: 10px;
}

/deep/.van-dropdown-menu__bar {
    border-radius: 15px;
    height: 60px;
}
</style>
