<template>
  <div class="echarts">
    <van-nav-bar
      class="title"
      title="实时数据"
      :left-text="wat"
      left-arrow
      @click-left="$router.push('/home')"
    />
    <van-datetime-picker
      v-model="currentDate"
      type="year-month"
      title="请选择年月"
      :min-date="minDate"
      :max-date="maxDate"
      :formatter="formatter"
      @confirm="getOk"
    />
    <van-dropdown-menu class="select">
      <van-dropdown-item
        :title="electricTitle"
        v-model="value"
        :options="option"
        @click.native="qiehuan(option[value])"
      />
    </van-dropdown-menu>
    <div class="contain">
      <div id="echart"></div>
      <div class="bottom">
        <ul class="tablehead">
          <li>日期</li>
          <li>流量</li>
          <li>电量</li>
          <li>流量电量比</li>
        </ul>
        <ul class="main" v-for="item in echartList" :key="item.index">
          <li>{{ item.date }}</li>
          <!-- 日期 -->
          <li>{{ item.effluentFlow }}T/HT</li>
          <!-- 流量 -->
          <li>{{ item.effluentRatioElectric }}kw.h</li>
          <!-- 电量 -->
          <li>{{ item.electricQuantity }}%</li>
          <!-- 流量电量比 -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { chartApi, standList } from "../../api/api";
export default {
  data() {
    return {
      csDate: `${new Date().getFullYear()}-${(
        "0" +
        (new Date().getMonth() + 1)
      ).slice(-2)}`, // 默认数据
      date: "", // 选择的日期
      show: true,
      wat: "",
      value: 0,
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      echartList: [],
      dateList: [],
      liuList: [],
      dList: [],
      bList: [],
      uuid: "all",
      option: [],
      isInit: true,
      electricTitle: "泵站选择",
    };
  },
  mounted() {
    this.getLoadEcharts();
  },
  created() {
    this.wat = localStorage.getItem("wname");
    this.getStands();
    this.getChart();
    const that = this;
    setTimeout(function () {
      that.initEchart();
    }, 1000);
  },
  methods: {
    initEchart() {
      chartApi({
        data: {
          puuid: localStorage.getItem("waterId"),
          uuid: this.uuid,
          time: this.csDate,
          sAccount: localStorage.getItem("loginName"),
          action: "getRunReportTableInfo",
        },
      }).then((res) => {
        this.dateList = [];
        this.liuList = [];
        this.dList = [];
        this.bList = [];
        this.echartList = res.data.data;
        this.echartList.forEach((item) => {
          this.dateList.push(item.date);
          this.liuList.push(item.effluentFlow);
          this.dList.push(item.effluentRatioElectric);
          this.bList.push(item.electricQuantity);
        });
        const that = this;
        setTimeout(function () {
          that.getLoadEcharts();
        }, 1000);
      });
    },
    // 获取泵站列表
    getStands() {
      standList({
        data: {
          action: "getPumpstationListInfo",
          puuid: localStorage.getItem("waterId"),
          uuid: "all",
          run: true,
          stop: true,
          fault: true,
          sAccount: localStorage.getItem("loginName"),
        },
      }).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          i === 0 &&
            this.option.push({
              text: "全部",
              value: i,
              uuid: "all",
            });
          const person = {
            text: res.data.data[i].title,
            value: i + 1,
            uuid: res.data.data[i].uuid,
          };
          this.option.push(person);
        }
        this.getChart();
      });
    },

    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    getOk() {
      const month = this.currentDate.getMonth() + 1;
      this.date = `${this.currentDate.getFullYear()}-${("0" + month).slice(
        -2
      )}`;
      this.getChart();
      const that = this;
      setTimeout(function () {
        that.getLoadEcharts();
      }, 1000);
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
    },
    getLoadEcharts() {
      var chartDom = document.getElementById("echart");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: [
          {
            left: "left",
            text: "实时流量/电量查询折线图",
            padding: [20, 0, 0, 0],
            textStyle: {
              fontSize: 14,
            },
          },
          {
            right: "right",
            text: "流量T/HT 电量 kw.h",
            padding: [20, 0, 0, 10],
            textStyle: {
              fontSize: 14,
            },
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["流量", "电量", "流量电量比"],
          bottom: 0,
        },
        grid: {
          left: "0%",
          right: "5%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false, // 显示间距设置为false
          axisTick: {
            inside: true,
          },
          data: this.dateList,
        },
        yAxis: {
          axisTick: {
            inside: true,
          },
          type: "category",
          boundaryGap: false, // 显示间距设置为false
          // data: [0, 2, 4, 6, 8, 10, 12, 14, 16]
        },
        series: [
          {
            name: "流量",
            type: "line",
            stack: "Total",
            data: this.liuList,
          },
          {
            name: "电量",
            type: "line",
            stack: "Total",
            data: this.dList,
          },
          {
            name: "流量电量比",
            type: "line",
            stack: "Total",
            data: this.bList,
          },
        ],
      };

      myChart.setOption(option);
    },
    // echart数据接口请求
    getChart() {
      chartApi({
        data: {
          puuid: localStorage.getItem("waterId"),
          uuid: this.uuid,
          time: this.date,
          sAccount: localStorage.getItem("loginName"),
          action: "getRunReportTableInfo",
        },
      }).then((res) => {
        this.dateList = [];
        this.liuList = [];
        this.dList = [];
        this.bList = [];
        this.echartList = res.data.data;
        this.echartList.forEach((item) => {
          this.dateList.push(item.date);
          this.liuList.push(item.effluentFlow);
          this.dList.push(item.effluentRatioElectric);
          this.bList.push(item.electricQuantity);
        });
        this.echartList = JSON.parse(JSON.stringify(this.echartList));
        this.getLoadEcharts();
      });
    },
    qiehuan(val) {
      this.electricTitle = this.option.find(
        (item) => item.value === this.value
      )?.text;
      this.uuid = val.uuid;
      this.getChart();
      this.getLoadEcharts();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__title {
  color: white;
}

.main:nth-child(even) {
  background-color: rgb(247, 247, 247);
}

.main:nth-child(odd) {
  background-color: #fff;
}

.bottom {
  width: 100%;
  height: 300px;
  overflow: scroll;

  .main {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;

    li {
      height: 40px;
      line-height: 40px;
    }
  }

  .tablehead {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;

    li {
      height: 40px;
      line-height: 40px;
    }
  }
}

.contain {
  height: 100vh;
  margin: 0 20px;
  border-radius: 20px;

  #echart {
    height: 350px;
    width: 100%;
  }
}

/deep/.van-nav-bar__content {
  height: 100px;
  background-color: rgb(87, 143, 254);
}

.van-nav-bar {
  background-color: rgb(241, 186, 66);
}

::v-deep .van-icon {
  color: #fff;
}

::v-deep .van-nav-bar__text {
  color: #fff;
}

::v-deep .van-ellipsis {
  font-size: 18px;
}
</style>
