<template>
  <div class="errorList">
    <van-nav-bar
      title=""
      @nav-bar-title-font-size="20"
      left-text="报警列表"
      left-arrow
      @click-left="$router.push('/home')"
    >
      <template #right>
        <van-icon name="wap-nav" size="20" @click="lists" />
      </template>
    </van-nav-bar>
    <div class="link">
      <div class="option">
        <van-popup v-model="isShow" position="top" :style="{ height: '280px' }">
          <ul>
            <router-link to="/home">
              <li>
                <img
                  src="../../assets/images/img/fontImgs/zy.png"
                  alt=""
                />返回主页
              </li>
            </router-link>

            <router-link to="/policelist">
              <li>
                <img src="@/assets/images/img/fontImgs/bj.png" alt="" />报警列表
              </li>
            </router-link>

            <router-link to="/errorlist">
              <li>
                <img src="@/assets/images/img/fontImgs/gz.png" alt="" />故障列表
              </li>
            </router-link>

            <router-link to="/echarts">
              <li>
                <img src="@/assets/images/img/fontImgs/sz.png" alt="" />运行报表
              </li>
            </router-link>

            <router-link to="/pwdm">
              <li>
                <img src="@/assets/images/img/fontImgs/mm.png" alt="" />修改密码
              </li>
            </router-link>

            <a href="" @click="quit">
              <li>
                <img src="@/assets/images/img/fontImgs/fh.png" alt="" />安全退出
              </li>
            </a>
          </ul>
        </van-popup>
      </div>
    </div>
    <van-tabs @click="onClick">
      <!-- 选择年月日 -->
      <van-tab title="未处理">
        <van-cell
          title="请选择日期区间"
          :value="ZSws + ' ~ ' + ZSwe"
          @click="show = true"
        />
        <van-calendar
          v-model="show"
          type="range"
          @confirm="onConfirm"
          :min-date="minDate"
          :default-date="[yesterday, today]"
        />
        <!-- 未处理 -->
        <div class="listItem">
          <table class="table">
            <thead>
              <th>发生时间/最新时间</th>
              <th>报警设备</th>
              <th>报警内容</th>
            </thead>
            <tbody>
              <tr v-for="item in weiList" :key="item.index">
                <td style="line-height: 15px; margin: 0">
                  {{ item.faultStartTime }}<br />—<br />{{ item.faultLastTime }}
                </td>
                <td>{{ item.pumpStationNum }} {{ item.pumpsNum }}</td>
                <td>{{ item.faultMsg }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="block"
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <el-pagination
            small
            :background="true"
            @size-change="weihandleSizeChange"
            @current-change="weihandleCurrentChange"
            :pager-count="5"
            :current-page="weicurrentPage"
            :page-sizes="[20, 50, 100]"
            :page-size="weipages"
            layout="total, sizes, prev, pager, next"
            :total="weitotal"
          >
          </el-pagination>
        </div>
      </van-tab>
      <van-tab title="已处理">
        <van-cell
          title="请选择日期区间"
          :value="ZSys + ' ~ ' + ZSye"
          @click="show = true"
        />
        <van-calendar
          v-model="show"
          type="range"
          @confirm="onConfirmy"
          :min-date="minDate"
          :default-date="[yesterday, today]"
        />
        <!-- 已处理 -->
        <div class="listItem">
          <table class="table">
            <thead>
              <th>发生时间/处理时间</th>
              <th>报警设备</th>
              <th>报警内容</th>
            </thead>
            <tbody>
              <tr v-for="item in yiList" :key="item.index">
                <td style="line-height: 15px; margin: 0">
                  {{ item.faultStartTime }}<br />—<br />{{ item.faultLastTime }}
                </td>
                <td>{{ item.pumpStationNum }} {{ item.pumpsNum }}</td>
                <td>{{ item.faultMsg }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="block"
          style="
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <el-pagination
            small
            :background="true"
            @size-change="yihandleSizeChange"
            @current-change="yihandleCurrentChange"
            :pager-count="5"
            :current-page="yicurrentPage"
            :page-sizes="[20, 50, 100]"
            :page-size="yipages"
            layout="total, sizes, prev, pager, next"
            :total="yitotal"
          >
          </el-pagination>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Toast } from "vant";
import { policeWList, policeYList } from "@/api/api.js";
// const ws = new WebSocket("ws://pumpwebsocketlan.gbhjt.com:4101");
export default {
  data() {
    return {
      weitotal: "",
      weipages: 20, //每页显示几个
      weicurrentPage: 1, //第几页

      yitotal: "",
      yipages: 20, //每页显示几个
      yicurrentPage: 1, //第几页

      yesterday: "",
      today: "",

      ZSws: "",
      ZSwe: "",
      ZSys: "",
      ZSye: "",

      isShow: false,
      num: 0, // 控制显示隐藏
      date: "", // 选择的日期
      date1: "", // 选择的日期
      wdate: {
        start: "",
        end: "",
      },
      ydate: {
        start: "",
        end: "",
      },
      show: false,
      weiList: [], // 未处理列表
      yiList: [], // 已处理列表
      minDate: new Date(new Date().setDate(new Date().getDate() - 30)), // 这里是重点
    };
  },
  watch: {},
  created() {
    this.gettimes();
    this.creatwApi();
    this.creatyApi();
  },
  mounted() {
    // ws.addEventListener("open", this.handleWsOpen.bind(this), false);
    // ws.addEventListener("close", this.handleWsClose.bind(this), false);
    // ws.addEventListener("error", this.handleWsError.bind(this), false);
    // ws.addEventListener("message", this.handleWsMessage.bind(this), false);
  },
  methods: {
    weihandleSizeChange(e) {
      this.weipages = e;
      this.getWapi();
    },
    weihandleCurrentChange(e) {
      this.weicurrentPage = e;
      this.getWapi();
    },
    yihandleSizeChange(e) {
      this.yipages = e;
      this.getYapi();
    },
    yihandleCurrentChange(e) {
      this.yicurrentPage = e;
      this.getYapi();
    },
    set_time(time) {
      let date = new Date(time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let Y = date.getFullYear() + "/";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "/";
      let D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + M + D;
    },
    gettimes() {
      //获取今天日期-vant里面要传的格式Wed Feb 23 2022 00:00:00 GMT+0800 (中国标准时间)
      let t1 = this.set_time(Date.parse(new Date()) / 1000);
      this.today = new Date(t1);
      console.log("this.today", this.today);

      //获取昨天日期-vant里面要传的格式Tue Feb 22 2022 00:00:00 GMT+0800 (中国标准时间)
      let y1 = new Date();
      y1.setTime(y1.getTime() - 7 * 24 * 60 * 60 * 1000);
      let s1 =
        y1.getFullYear() + "/" + (y1.getMonth() + 1) + "/" + y1.getDate();
      this.yesterday = new Date(s1);

      this.ZSws = this.formatDate(this.yesterday);
      this.ZSwe = this.formatDate(this.today);
      this.ZSys = this.formatDate(this.yesterday);
      this.ZSye = this.formatDate(this.today);
      this.wdate.start = this.formatDate(this.yesterday) + " " + "0:0:0";
      this.wdate.end = this.formatDate(this.today) + " " + "23:59:59";
      this.ydate.start = this.formatDate(this.yesterday) + " " + "0:0:0";
      this.ydate.end = this.formatDate(this.today) + " " + "23:59:59";
      this.getWapi();
    },

    // handleWsOpen(e) {
    //   // Toast({
    //   //   message: "报警socket连接成功",
    //   //   position: "top",
    //   // });
    //   ws.send(
    //     JSON.stringify({
    //       loginName: localStorage.getItem("loginName"),
    //       companyID: localStorage.getItem("companyId"),
    //       type: "alarm",
    //     })
    //   );
    // },
    // handleWsClose(e) {
    //   console.log("close", e);
    // },
    // handleWsError(e) {
    //   console.log("error", e);
    //   Toast({
    //     message: "报警socket连接失败",
    //     position: "top",
    //   });
    // },
    // handleWsMessage(e) {
    //   this.getWapi();
    //   this.creatyApi();
    // },
    // 初始化未数据
    creatwApi() {
      const myDate = new Date();
      const arr = new Date().toLocaleDateString() + " " + "23:59:59";
      const lw = new Date(myDate - 1000 * 60 * 60 * 24 * 7);
      const lastY = lw.getFullYear();
      const lastM = lw.getMonth() + 1;
      const lastD = lw.getDate();
      const startdate =
        lastY +
        "/" +
        (lastM < 10 ? "0" + lastM : lastM) +
        "/" +
        (lastD < 10 ? "0" + lastD : lastD) +
        " " +
        "0:0:0";
      policeWList({
        data: {
          puuid: localStorage.getItem("waterId"),
          startDate: startdate,
          endDate: arr,
          currentPage: 1,
          pageSize: this.weipages,
          sAccount: localStorage.getItem("loginName"),
          action: "getAlarmUnporcessInfo",
        },
      }).then((res) => {
        this.weiList = res.data.list;
        this.weitotal = res.data.total;
        // if (res.data.ReturnState.Dis === '记录集为空') Toast('当前站点暂未查询到报警数据，请返回站点选择页后重试')
      });
    },
    // 初始化已数据
    creatyApi() {
      const myDate = new Date();
      const arr = new Date().toLocaleDateString() + " " + "23:59:59";
      const lw = new Date(myDate - 1000 * 60 * 60 * 24 * 7);
      const lastY = lw.getFullYear();
      const lastM = lw.getMonth() + 1;
      const lastD = lw.getDate();
      const startdate =
        lastY +
        "/" +
        (lastM < 10 ? "0" + lastM : lastM) +
        "/" +
        (lastD < 10 ? "0" + lastD : lastD) +
        " " +
        "0:0:0";
      policeWList({
        data: {
          puuid: localStorage.getItem("waterId"),
          startDate: startdate,
          endDate: arr,
          currentPage: 1,
          pageSize: this.yipages,
          sAccount: localStorage.getItem("loginName"),
          action: "getAlarmporcessedInfo",
        },
      }).then((res) => {
        this.yiList = res.data.list;
        this.yitotal = res.data.total;
      });
    },
    // 退出登录
    quit() {
    //   localStorage.removeItem("userinfo");
      this.$router.push("/login");
    },
    // 多功能选择
    lists() {
      this.num++;
      if (this.num === 1) {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.num = 0;
      }
    },
    // 未处理已处理切换
    onClick(name, title) {
      if (title == "已处理") {
        this.getYapi();
      } else {
        this.getWapi();
      }
    },
    //
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    // 未
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.wdate = {
        // `${this.formatDate(start)} - ${this.formatDate(end)}`
        start: this.formatDate(start) + " " + "0:0:0",
        end: this.formatDate(end) + " " + "23:59:59",
      };
      this.ZSws = this.formatDate(start);
      this.ZSwe = this.formatDate(end);
      this.getWapi();
    },
    // 已
    onConfirmy(date) {
      const [start, end] = date;
      this.show = false;
      this.ydate = {
        // `${this.formatDate(start)} - ${this.formatDate(end)}`
        start: this.formatDate(start) + " " + "0:0:0",
        end: this.formatDate(end) + " " + "23:59:59",
      };
      this.ZSys = this.formatDate(start);
      this.ZSye = this.formatDate(end);
      this.getYapi();
    },
    // 未处理请求列表
    getWapi() {
      policeWList({
        data: {
          puuid: localStorage.getItem("waterId"),
          startDate: this.wdate.start,
          endDate: this.wdate.end,
          currentPage: this.weicurrentPage,
          pageSize: this.weipages,
          sAccount: localStorage.getItem("loginName"),
          action: "getAlarmUnporcessInfo",
        },
      }).then((res) => {
        this.weiList = res.data.list;
        this.weitotal = res.data.total;
        if (res.data.ReturnState.Dis === "记录集为空") Toast("暂无数据");
      });
    },
    // 已处理请求列表
    getYapi() {
      policeYList({
        data: {
          puuid: localStorage.getItem("waterId"),
          startDate: this.ydate.start,
          endDate: this.ydate.end,
          currentPage: this.yicurrentPage,
          pageSize: this.yipages,
          sAccount: localStorage.getItem("loginName"),
          action: "getAlarmporcessedInfo",
        },
      }).then((res) => {
        this.yiList = res.data.list;
        this.yitotal = res.data.total;
        if (res.data.ReturnState.Dis === "记录集为空") Toast("暂无数据");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.errorList {
  width: 100%;
  height: 100vh;
  background-image: url("../../assets/images/img/PumpstationMonitor/pumpstationMonitor_waterDrop.png");
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .tabCut {
    width: 100%;
    height: 50px;
    background-color: rgb(86, 144, 254);
    z-index: 1000;
    border: none;
  }

  hr {
    width: 85%;
  }

  .listItem {
    width: 85%;
    margin: 0 auto;
    height: 60vh;
    margin-top: 20px;
    overflow: scroll;

    .table {
      width: 100%;
      border-collapse: collapse;

      thead {
        th {
          font-size: 14px;
          text-align: center;
          border: 1px solid rgba(189, 189, 189, 0.5);
          height: 30px;
          line-height: 30px;
        }
      }

      tr {
        height: 30px;
        line-height: 30px;

        td {
          text-align: center;
          font-size: 13px;
          border: 1px solid rgba(189, 189, 189, 0.5);
          padding: 5px 3px;
        }
      }
    }
  }
}

.link .option ul li a[data-v-4c93389f] {
  font-size: 13px;
}
.block {
  margin-top: 20px;
  box-sizing: border-box;
  width: 100vw;
  /deep/.el-pagination__sizes {
    margin-top: -3px;
  }
  /deep/.number {
    display: none;
  }
  /deep/.active {
    display: block;
  }
  /deep/ .el-icon-more {
    display: none;
  }
}
.option {
  width: 75px;
  height: 80px;
  position: absolute;
  // z-index: 1001;
  right: 60px;
  top: 20px;

  .van-popup {
    background: #5690fe;
  }

  ul {
    position: absolute;
    width: 100%;
    z-index: 1000;
    height: 100%;

    a {
      color: #fff;
      font-size: 16px;
      margin-left: 5px;
      display: block;
      line-height: 25px;
    }

    li {
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid #f5f5f5;
      padding-left: 20px;

      img {
        width: 20px;
        vertical-align: sub;
      }

      a {
        color: #fff;
        line-height: 25px;
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }

  .lsit {
    width: 125%;
    height: 150%;
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

[data-v-4c93389f] .van-nav-bar .van-icon {
  position: relative;
}

/deep/.van-nav-bar__content {
  background-color: rgb(86, 144, 254);
  height: 10vh;
  z-index: 100;
}

/deep/.van-nav-bar .van-icon {
  color: white;
}

/deep/.van-nav-bar__text {
  color: white;
}

/deep/.van-hairline--bottom:after {
  border-bottom-width: 0;
}

.line {
  width: 80%;
  height: 1px;
  background-color: grey;
  margin: 0 auto;
  z-index: 1000;
}
</style>
