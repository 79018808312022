/*
 * @description:深拷贝
 * @param {any}
 * 
 */
const deepCopy = (copy) => {

    let result;

    let recursion = (copyed, copying) => {

        if (copyed && typeof copyed == `object`) {

            if (Array.isArray(copyed)) {

                copyed.forEach((item, index) => {

                    if (item && typeof item == `object`) {
                        Array.isArray(item) ? copying[index] = [] : copying[index] = {}
                        recursion(item, copying[index])
                    } else {
                        copying[index] = item;
                    }

                })

            } else {

                for (let prop in copyed) {
                    if (copyed[prop] && typeof copyed[prop] == `object`) {

                        Array.isArray(copyed[prop]) ? copying[prop] = [] : copying[prop] = {}
                        recursion(copyed[prop], copying[prop])
                    } else {
                        copying[prop] = copyed[prop];
                    }
                }

            }

        } else {
            copying = copyed;
        }

    };

    copy &&
        copy instanceof Object &&
        (Array.isArray(copy) ? result = [] : result = {}, recursion(copy, result), true) || (result = copy);

    return result;
}

/*
 * @description: 深赋值 //以被赋值对象为准
 * assigned: 被赋值的对象
 * assigning: 赋值对象
 * 
 */

const deepAssignment = (assigned, assigning, cb) => {

    let recursion = ($assigned, $assigning, $cb) => {

        if ($assigned && $assigning && typeof $assigned == `object` && typeof $assigning == `object`) {

            if (!Array.isArray($assigned) && !Array.isArray($assigning)) {
                //纯object对象以被赋值为准
                for (let prop in $assigned) {

                    if ($assigning.hasOwnProperty(prop)) {

                        if ($assigning[prop] && typeof $assigning[prop] == `object` && $assigned[prop] && typeof $assigned[prop] == `object`) {

                            if (Array.isArray($assigned[prop]) && Array.isArray($assigning[prop])) {
                                if (typeof $cb == `function`) {

                                    $assigning.hasOwnProperty(prop) &&
                                        $cb({ assigned: $assigned, assigning: $assigning, prop, oldVal: $assigned[prop], newVal: $assigning[prop] })

                                } else {

                                    $assigning.hasOwnProperty(prop) &&
                                        ($assigned[prop] = $assigning[prop]);

                                }
                            } else {
                                recursion($assigned[prop], $assigning[prop], $cb)
                            }

                        } else {
                            if (typeof $cb == `function`) {

                                $assigning.hasOwnProperty(prop) &&
                                    $cb({ assigned: $assigned, assigning: $assigning, prop, oldVal: $assigned[prop], newVal: $assigning[prop] })

                            } else {

                                $assigning.hasOwnProperty(prop) &&
                                    ($assigned[prop] = $assigning[prop]);

                            }

                        }

                    }
                }
            } else {

                if (typeof $cb == `function`) {

                    $cb({ assigned: $assigned, assigning: $assigning, prop: void 0, oldVal: $assigned, newVal: $assigning })

                }

            }

        }

    }

    recursion(assigned, assigning, cb)
}

export {
    deepCopy,
    deepAssignment
}
