<template>
  <div class="login">
    <van-nav-bar
      title="密码管理"
      @nav-bar-title-font-size="20"
      left-text="返回"
      left-arrow
      @click-left="$router.push('/home')"
    />
    <div class="main">
      <van-form :model="pwdm" ref="passRef" @submit="submit">
        <van-field
          v-model="pwdm.sAccount"
          type="text"
          name="账户"
          :rules="user"
          placeholder="请输入需要修改的账户"
        />
        <div class="nav-to">
          <van-field
            v-model="pwdm.oldpass"
            type="password"
            name="旧密码"
            :rules="[{ require: true, message: '请输入当前密码' }]"
            placeholder="请输入旧密码"
            class="old"
          />
          <router-link to="/reset" class="pass">忘记密码？</router-link>
        </div>
        <van-field
          v-model.trim="pwdm.newpass"
          type="password"
          name="新密码"
          :rules="newPswd"
          placeholder="设置新密码"
        />
        <van-field
          v-model="pwdm.againpass"
          type="password"
          name="确认新密码"
          :rules="[
            { validator, message: '两次密码输入不一致' },
            { required: true, message: '请再次填写新密码', trigger: 'onBlur' },
          ]"
          placeholder="再次确认新密码"
        />
        <div style="margin: 16px">
          <van-button round block type="info">确认</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { pwd } from "@/api/api.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      pwdm: {
        oldpass: "", // 旧密码
        newpass: "", // 新密码
        againpass: "", // 再次确认
        sAccount: "", // 要修改的账户
      },
      newPswd: [
        { required: true, message: "请填写新密码", trigger: "onBlur" },
        {
          min: 3,
          max: 16,
          message: "长度在 3 到 16 个字符",
          trigger: "onBlur",
        },
      ],
      user: [
        {
          required: true,
          message: "请填写要修改的账户名称",
          trigger: "onBlur",
        },
        {
          pattern: /^[\s\S]*.*[^\s][\s\S]*$/,
          message: "不能为空",
          trigger: "onBlur",
        },
      ],
    };
  },
  methods: {
    // 校验两次密码是否一致
    validator(val) {
      if (val !== this.pwdm.newpass) return false;
    },
    // 提交表单
    submit() {
      pwd({
        data: {
          action: "updatePassword",
          sAccount: this.pwdm.sAccount,
          OldPwd: this.pwdm.oldpass,
          NewPwd1: this.pwdm.newpass,
          NewPwd2: this.pwdm.againpass,
          optionUser: localStorage.getItem("loginName"),
        },
      })
        .then((res) => {
          console.log(res);
          Toast({
            message: res.data.Msg,
            position: "top",
          });
          this.pwdm = {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10% 0;

    h1 {
      font-size: 22px;
      font-weight: 600;
    }

    .register {
      font-size: 18px;
      color: black;
    }
  }

  .nav-to {
    position: relative;

    .pass {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 15px;
      margin-right: 8px;
      font-size: 13px;
      color: black;
    }
  }

  /deep/.van-nav-bar__content {
    height: 100px;
  }

  .main {
    padding: 20% 0;

    .van-cell::after {
      border-bottom: 2px solid black;
    }

    .van-cell {
      margin-top: 25px;
    }
  }
}

.van-button {
  margin-top: 50px;
}
</style>
